body {
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: "Inter"; */
  color: #605b5b;
  font-size: 13px;
  margin: 1%;
  padding: 0%;
  text-decoration: none;
  /* overflow-x: hidden; */
}
.main-container {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #0873c4;
}
.test-container {
  background-color: palegoldenrod;
  width: 500px;
  height: 500px;
}

.test-box {
  width: 200px;
  height: 200px;
  position: relative;
  background-color: pink;
}

.test-box2 {
  box-sizing: border-box;
  border: 10px solid black;
  width: 50%;
  height: 50%;
  position: absolute;
  margin-top: 50%;
  background-color: rgb(57, 207, 41);
}
.test-box3 {
  width: 50%;
  height: 50%;
  background-color: rgb(82, 58, 235);
}

.logo {
  margin-right: 20px;
}

.nav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
  padding-top: 10px;
}
.top-nav {
  display: flex;
  justify-content: flex-end;
}
.nav {
  background-color: #fff;
  display: flex;
}
.nav-links {
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
  font-size: 1rem;
}

.nav-links a {
  color: #706666;
  text-decoration: none;
  height: 1.5rem;
  text-align: center;
}
.nav-links a:hover {
  color: #f77b4d;
}

.nav-toggle {
  display: none;
}

.test-img {
  background-image: url(images/flower.png);
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
}
.quote img {
  /* background-image: url(./images/flower.png); */
  display: block;
  width: 100%;
  height: 40%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 5px solid #f77b4d;
  border-bottom: 5px solid #f77b4d;
}

.text-overlay {
  position: absolute;
  top: 210px;
  left: 60px;
  line-height: 1.5rem;
  z-index: 1; /* Ensure the text appears above the image */
  color: white;
  font-size: 1.1rem;
  width: 530px;
  height: 500px;
  text-align: center;
}

.text-overlay a {
  color: #fee096;
}
.wish-msg {
  padding: 1px;
  margin-top: 15px;
  border-radius: 8px;
  background-color: rgba(246, 246, 246, 0.6);
}

.wish-msg > h2 {
  color: #004a77;
}
.wish-msg > p {
  color: #000;
  font-size: 20px;
}

.container {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.left-container {
  width: 72%;
}
.right-container {
  width: 27%;
}

.outer-box {
  box-sizing: border-box;
  border: 1.5px solid #bcbcbc;
  border-radius: 3px;
  width: 100%;
  margin-top: 1%;
  padding-bottom: 2%;
  background-color: #fcfbfb;
  margin-bottom: 1%;
}

.prog-top {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
  /* margin: 1%; */
}

.prog-btn {
  color: #0577b6;
  font-family: Open Sans;
  font-size: 1rem;
  border: none;
  padding: 5px;
}
/* .vl {
  width: 3px;
  height: 30px;
  background-color: silver;
  display: block;
  position: absolute;
} */

.prog-date {
}

.calender {
  border: none;
}
.prog-head {
  display: flex;
  flex-wrap: wrap;
}
.programcard {
  width: 45%;
  border-radius: 5px;
  border: 1.5px solid #ffac33;
  background: #fff;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 1%;
}

.programcard-head {
  display: flex;
  /* margin: 2% 0% 2% 0%; */
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin-left: 5%;
}
.flyer {
  width: 92%;
  height: 400px;
}
.inperson-flyer {
  width: 92%;
  height: auto;
}
.circle-img {
  border-radius: 50%;
  border: 5px solid #fff;
  width: 27px;
  height: 27px;
  background-color: #bcbcbc;
  margin: 1%;
}

.date-time {
  font-size: 0.92rem;
  font-weight: 500;
}

.progcard-title p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.progcard-title {
  /* align-items: center; */
  color: #443d3d;
  font-family: "Open Sans", sans-serif;
  border-spacing: 2px;
  margin-bottom: 10px;
  margin-top: 10px;
  /* margin-left: 30px; */
  /* font-size: 0.9rem; */
  /* justify-content: center; */
  /* text-align: center; */
  /* display: flex; */
}

.programcard-type {
  /* width: 36%; */
  display: inline-block;
  padding: 1.2%;
  border-radius: 0px 15px 15px 0px;
  background: #f77b4d;
  color: #fff;
  padding-right: 15px;
  /* font-size: small; */
  margin-bottom: 3%;
}
.programcard-body {
  justify-content: center;
  padding: 4%;
  /* font-size: 0.9rem; */
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #605b5b;
  /* text-align: center; */
  text-decoration: none;
}
.card-details {
  font-size: medium;
}
.hl {
  width: 90%;
}
.progarm-schedule {
  text-align: center;
  padding: 4%;
  font-size: 0.9rem;
}
.program-register {
  background-color: #ac751d;
  color: #fff;
  height: 2rem;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  padding: 10px;
}
.register {
  margin-top: 12%;
  width: 40%;
  height: 30%;
  border-radius: 14px;
  background: #ac751d;
  color: #fff;
  border: 0px;
}

.vl {
  border: 1px solid rgb(94, 98, 94);
  height: 60px;
  margin-left: 20px;
  margin-top: 10px;
}

.booksClubCard {
  width: 47%;
  margin-bottom: 1%;
  margin-left: 1%;
  border-radius: 4px;
  border: 1.5px solid #ffac33;
  background: #fffef9;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 2px;
  margin-top: 2%;
}
.booksClubCard-head {
  display: flex;
  align-items: center;
}
.booksClubCard-head > .circle-img {
  background-color: #ffcc4d;
}

.books-list {
  display: flex;
  padding-left: 20px;
}

.inperson-container {
  border-radius: 3px;
  border: 1px solid #bcbcbc;
  background: #fcfbfb;
}

.gmap {
  width: 717px;
  height: 207px;
  border-radius: 7px;
  border: 1px solid #999;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.celebration {
  width: 260px;
  height: 207px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #999;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.master-card {
  width: 134px;
  height: 165px;
  flex-shrink: 0;
  border-radius: 14px 14px 0px 0px;
  border: 1px solid #d6c6c6;
  background: #fff;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.master {
  display: flex;
  margin: 2%;
  justify-content: space-between;
}

.city {
  margin-left: 1%;
  border: none;
  color: #136ca1;
  font-size: medium;
}
.local-events {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.event-card {
  width: 31%;
  margin: 10px;
  height: 360px;
  border-radius: 5px;
  border: 1.5px solid #ffac33;
  background: #fff;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.event-title {
  font-size: medium;
  font-weight: bold;
  padding: 10px;
}

.event-image {
  background-repeat: no-repeat;
  width: 97%;
  height: 300px;
}
hr {
  width: 97%;
  margin-top: 2%;
  margin-bottom: 2%;
  height: 1px;
  border: none;
  background-color: #9f9f9f;
}

.donation-box {
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  height: 278px;
  background-color: #056eae;
  border-radius: 5px;
  color: #fff;
  display: flex;
  /* font-size: medium; */
}

.no-bullets {
  list-style-type: none;
}
li.menu-item:not(:last-child) {
  margin-bottom: 25px;
}
.give-msg {
  font-weight: bold;
  margin-top: 4%;
  margin-left: 2%;
  margin-right: 10%;
  font-size: 1rem;
}
.donation-type {
  margin-top: 3%;
  margin-left: 3%;
  font-size: 0.9rem;
  font-weight: initial;
}

.nav-donate-btn {
  background-color: #f4900c;
  font-size: 1rem;
  color: #fff;
  border: 2px solid rgba(194, 194, 194, 0.899);
  border-radius: 5px;
  padding: 8px;
  letter-spacing: 5px;
  font-weight: bold;
}

.btn-donate {
  width: 150px;
  height: 60px;
  background-color: #f4900c;
  border: 2px solid #fff;
  border-radius: 5px;
  margin-left: 50px;
  margin-top: 45%;
  color: #fff;
  letter-spacing: 5px;
  font-weight: bold;
  align-items: flex-end;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.menu-item-type {
  margin-bottom: 10px;
}

.love-svg {
  margin-top: 50px;
  margin-left: 20px;
}

.vl-donation {
  border-left: 1px solid #78c1ee;
  height: 190px;
  margin: 40px 0px 0px 30px;
}

.vol {
  display: flex;
  margin-bottom: 2%;
}

.btn-vol {
  display: inline-block;
  width: 150px;
  height: 60px;
  background-color: #0091e9;
  border: 2px solid #fff;
  border-radius: 5px;
  margin-left: 50px;
  margin-right: 50px;
  color: #fff;
  letter-spacing: 5px;
  font-weight: bold;
}

.vol-text {
  margin-left: 1%;
  font-size: 0.9rem;
  padding-left: 5px;
  justify-content: space-evenly;
}

.footer {
  display: flex;
  background-color: #f3af42;
  height: 78px;
  align-items: center;
  justify-content: center;
  font-size: small;
  color: black;
}
.about-patriji {
  background-color: #fffae6;
  margin-top: 2%;
  margin-bottom: 1%;
}
.patriji-title {
  text-align: center;
  padding-top: 10%;
  padding-bottom: 5%;
}

.patriji-img {
  align-items: center;
  text-align: center;
  padding-bottom: 5px;
}

.patriji-bg-img {
  /* margin-bottom: 100%; */
  /* width: 350px;
  height: 350px; */
  /* border: orangered solid 5px; */
  /* border-radius: 50%; */
}

.hr-about {
  width: 97%;
  margin-top: 5%;
  margin-bottom: 5%;
  height: 1px;
  border: none;
  background-color: #ffac33;
}

.circle-btn-container {
  display: flex;
  justify-content: center;
  padding-bottom: 5%;
}
.circle-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ffcc4d;
}

.circle-btn-txt {
  font-size: 0.8rem;
}

.circle-card {
  margin: 15px;
}

.patriji-txt {
  margin-top: 2%;
  font-size: 14px;
  margin-bottom: 2%;
  padding: 5px;
}

.meidation-img {
  margin-top: 20px;
  margin-left: 45px;
}

.meditation-desc {
  margin: 40px;
  margin-top: 25px;
}

.hr-meditation {
  width: 90%;
  background-color: #d8d8d8;
}

.progress-bar {
  display: flex;
  justify-content: center;
}

.progress-container {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 25px;
}

.progress-background {
  fill: none;
  stroke: #ddd;
  stroke-width: 10;
}

.progress-bar-1 {
  fill: none;
  stroke: #30bc79;
  stroke-width: 5;
  /* stroke-linecap: round; */
  stroke-dasharray: 252;
  stroke-dashoffset: 252;
  animation: progress-animation-1 2s ease-out forwards;
}

@keyframes progress-animation-1 {
  100% {
    stroke-dashoffset: 50.4; /* 65% completion: 65% * 2 * pi * 40 */
  }
}
.progress-bar-2 {
  fill: none;
  stroke: #30bc79;
  stroke-width: 5;
  /* stroke-linecap: round; */
  stroke-dasharray: 252;
  stroke-dashoffset: 252;
  animation: progress-animation-1 2s ease-out forwards;
}

@keyframes progress-animation-2 {
  100% {
    stroke-dashoffset: 27.72; /* 65% completion: 65% * 2 * pi * 40 */
  }
}

.percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #333;
}
.progress-text-1 {
  /* font-size: smaller; */
}

.bar-text {
  text-align: center;
  font-size: smaller;
}
.meidation-container {
  margin: 10px;
}
.spritual-container {
  margin-top: 100px;
}

.spt-block {
  align-items: center;
  display: flex;
  background-color: #0075c4;
  height: 50px;
  margin: 5px;
  color: #fff;
  justify-content: center;
}
.spiritual-books {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.magazines {
  border: 1px solid #e98862;
  justify-content: center;
  padding: 30px 15px 30px 15px;
  text-align: center;
  margin: 5px 2px 5px 2px;
  border-radius: 5px;
}

.magazines-authors {
  border: 1px solid #e98862;
  border-radius: 5px;
  justify-content: center;
  margin: 5px 2px 5px 2px;
  padding-right: 10px;
  font-size: 0.9rem;
}
.text-align {
  text-align: center;
}

.social-media-container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.social-media {
  text-align: center;
}
.social-media-icon {
  width: 200px;
}
.social-media-icons-block {
  display: flex;
}
.social-media-text {
  color: #0091e9;
}

.vl-social-media {
  border: 1px solid rgb(94, 98, 94);
  height: 60px;
  margin-left: 20px;
  margin-top: 10px;
}

.fb-icon {
  border-radius: 50%;
  border: 1px solid #0091e9;
}

.color-title {
  font-size: 1rem;
  font-weight: bold;
  color: #b43e10;
  padding: 10px;
}
.video-responsive {
  width: 99%;
  height: 500px;
  border-radius: 5px;
  padding: 3px;
}

.pyramid {
  display: flex;
}

.pyramid-desc {
  width: 34%;
  padding: 1%;
  font-size: medium;
  color: #999;
  margin: 1%;
}

.pyramid-experiment {
  display: flex;
  width: 62%;
}

.pyramid-exp-card {
  width: 47%;
  margin: 1%;
  height: 350px;
  border-radius: 5px;
  border: 1.5px solid #ffac33;
  background: #fff;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Container-title {
  margin: 1%;
  font-size: 1rem;
}

.card-video-responsive {
  padding: 1%;
  height: 250px;
  width: 93%;
  margin: 1%;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
}

.icon-align {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.media-icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 5px;
  margin-right: 5px;
}

.donate-details {
  text-align: center;
  border: #136ca1 solid;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  color: black;
  background-image: url("./images/bg2.avif");
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
}

.donate-details-heading {
  font-size: 2rem;
  font-weight: bolder;
}

.side-heading {
  font-size: 1.5rem;
  font-weight: bolder;
}

.side-value {
  font-size: 1.4rem;
  font-weight: 100;
}

.stay-tune {
  width: 60%;
  height: 60%;
  display: block;
  margin-top: 20px;
}
.stay-tune-container {
  display: flex;
  justify-content: center;
}

.mobile-version {
  display: none;
}

.venue {
  display: inline;
  color: gray;
}

.dev-by-bar {
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
}
.dev-by {
  display: inline;
}

.patriji-msg {
  font-size: 14px;
}

.read-more {
  color: #0091e9;
  border: none;
  background-color: #fffae6;
  display: inline-block;
  display: none;
}
